/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.onRouteUpdate = ({ location, prevLocation }) => {
  const s = document.createElement(`script`)
  s.type = `text/javascript`
  s.crossorigin = 'anonymous'
  s.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3580818377622359'
  s.onload = () => {
    try {
      window.adsbygoogle.push({})
    } catch (e) {}
    try {
      window.adsbygoogle.push({})
    } catch (e) {}
    try {
      window.adsbygoogle.push({})
    } catch (e) {}
    try {
      window.adsbygoogle.push({})
    } catch (e) {}
    try {
      window.adsbygoogle.push({})
    } catch (e) {}
    try {
      window.adsbygoogle.push({})
    } catch (e) {}
    try {
      window.adsbygoogle.push({})
    } catch (e) {}
    try {
      window.adsbygoogle.push({})
    } catch (e) {}
    try {
      window.adsbygoogle.push({})
    } catch (e) {}
    try {
      window.adsbygoogle.push({})
    } catch (e) {}
    try {
      window.adsbygoogle.push({})
    } catch (e) {}
    try {
      window.adsbygoogle.push({})
    } catch (e) {}
    try {
      window.adsbygoogle.push({})
    } catch (e) {}
  }
  document.getElementsByTagName(`body`)[0].appendChild(s)
}
