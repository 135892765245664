exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-templates-country-js": () => import("./../../../src/templates/Country.js" /* webpackChunkName: "component---src-templates-country-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/Home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/Search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-telephone-js": () => import("./../../../src/templates/Telephone.js" /* webpackChunkName: "component---src-templates-telephone-js" */),
  "component---src-templates-use-case-js": () => import("./../../../src/templates/UseCase.js" /* webpackChunkName: "component---src-templates-use-case-js" */)
}

